import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/produkty/sudafed_tabletki/" className="sel">
              {" "}
              &gt; SUDAFED® TABLETKI
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="product_content">
                <div className="product_name">
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      TABLETKI
                    </p>
                    <div className="desc" />
                  </div>
                  <div className="photo">
                    <span className="stamp ">
                      <img
                        src="/upload/znaczek_AZ3_v2.png"
                        alt="AZ3"
                        id="az3"
                      />
                      <img src="/upload/10h_znaczek.png" alt="10h" id="h10" />
                      <img
                        src="/upload/krople_znaczek.png"
                        alt="Zawiera kwas hialuronowy"
                        id="kh"
                      />
                      <img
                        src="/upload/zielony.png"
                        alt="Nie zawiera konserwantó"
                        id="nzk"
                      />
                    </span>
                    <img src="/web/kcfinder/uploads/files/produkty/tabletki_02.png" />
                  </div>
                </div>
                <div className="wysiwyg">
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Obfity, uporczywy katar, zatkany nos, a także uczucie ucisku
                    w zatokach spowodowane nagromadzoną{" "}
                    <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                      wydzieliną
                    </a>{" "}
                    utrudniają codzienne funkcjonowanie, a nieleczone mogą
                    prowadzić do poważniejszych infekcji. Sudafed® to lek w
                    formie tabletek powlekanych do wygodnego i dyskretnego
                    stosowania. Odblokowuje nos i zatoki ułatwiając oddychanie.
                    Zmniejsza ilość zalegającej wydzieliny oraz ułatwia jej
                    usunięcie eliminując ucisk w zatokach.
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <ul>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Odblokowuje nos i zatoki
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Eliminuje ucisk<sup>1</sup>
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Dyskretna i wygodna forma stosowania
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Dla dorosłych i dzieci od 12. roku &nbsp;życia
                    </li>
                  </ul>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Wskazania i dawkowanie:
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Preparat należy stosować w objawowym leczeniu{" "}
                    <a
                      href="/slowniczek/#zapalenia_blony_sluzowej_nosa"
                      target="_blank"
                    >
                      zapalenia błony śluzowej nosa
                    </a>
                    &nbsp;i zatok przynosowych w przebiegu przeziębienia, grypy
                    i{" "}
                    <a
                      href="/slowniczek/#alergiczny_niezyt_nosa"
                      target="_blank"
                    >
                      alergicznego nieżytu nosa
                    </a>
                    .&nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "12px"
                      }}
                    >
                      <span
                        className="s1"
                        clear
                        helvetica
                        style={{
                          "box-sizing": "border-box",
                          "font-family": ""
                        }}
                      >
                        Przeznaczony do stosowania u dorosłych i dzieci powyżej
                        12 roku życia. Jeśli lekarz nie zaleci inaczej, dorośli
                        powinni przyjmować jedną tabletkę 3-4 razy
                        dziennie.&nbsp;
                      </span>
                      <span
                        className="s1"
                        clear
                        helvetica
                        style={{
                          "box-sizing": "border-box",
                          "margin-bottom": "0px",
                          "font-family": ""
                        }}
                      >
                        Natomiast dzieciom preparat należy podawać maksymalnie
                        przez 4 dni, również 3-4 razy dziennie.
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "10px"
                      }}
                    >
                      <sup>1</sup>Dotyczy uczucia ucisku w zatokach
                      spowodowanego nagromadzoną wydzieliną i obrzękiem błony
                      śluzowej nosa.&nbsp;
                    </span>
                  </div>
                  <div>&nbsp;</div>
                </div>
                <a
                  href="/web/kcfinder/uploads/files/pdf/Sudafed-SmPC-06.2020.pdf"
                  className="pdf btn_link"
                  target="_blank"
                >
                  Przeczytaj ulotkę
                </a>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
                <div className="line">
                  <a href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru/">
                    <div className="c1">
                      <span className="photo" style={{}} />
                    </div>
                    <div className="c2">
                      Jak prawidłowo oczyszczać nos podczas kataru?{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/poradnik/katar/poznaj_rodzaje_kataru/">
                    <div className="c1">
                      <span className="photo" style={{}} />
                    </div>
                    <div className="c2">Poznaj rodzaje kataru </div>
                  </a>
                </div>
              </div>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="link_box video">
                <img src="/web/kcfinder/uploads/files/box/SudafedHAthumbnail.png" />
              </a>
              <a href="/produkty/sudafed_tabletki/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
